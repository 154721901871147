import React from 'react';
import ReactDOM from 'react-dom/client';
import { WordsContextProvider } from '@store/WordsContext';
import App from 'src/components/App';
import './index.scss';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <WordsContextProvider>
            <App />
        </WordsContextProvider>
        ,
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
