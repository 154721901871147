import { Translations } from '@models/interfaces';

const LegalContent: Translations = {
    es: {
        privacyContent: `<p>En jueletrado.com, valoramos tu privacidad. Aunque no recopilamos datos personales directamente, este sitio web utiliza Google Analytics para mejorar la experiencia de los usuarios al analizar el tráfico y el comportamiento de navegación de forma anónima.</p>
            <h2>Datos recopilados</h2>
            <p>A través de Google Analytics, se recopilan los siguientes datos anónimos:</p>
            <ul>
                <li>Dirección IP (anonimizada)</li>
                <li>Ubicación geográfica aproximada</li>
                <li>Duración de la visita</li>
                <li>Páginas visitadas</li>
                <li>Tipo de dispositivo y navegador</li>
            </ul>
            <h2>Uso de la información</h2>
            <p>Estos datos se utilizan exclusivamente para analizar el comportamiento de los usuarios en nuestro sitio web y mejorar los contenidos y servicios ofrecidos. No vendemos, compartimos ni utilizamos estos datos para identificar a los usuarios personalmente.</p>
            <h2>Uso de Cookies</h2>
            <p>Google Analytics utiliza cookies para rastrear la interacción de los usuarios con el sitio web. Las cookies son pequeños archivos que se almacenan en tu dispositivo para ayudar a analizar el uso del sitio web.</p>
            <h2>Servicios de Terceros</h2>
            <p>Utilizamos Google Analytics, un servicio de análisis web proporcionado por Google Inc. Google utiliza los datos recogidos para rastrear y examinar el uso de este sitio web, preparar informes y compartirlos con otros servicios de Google.</p>
            <h2>Tus Derechos</h2>
            <p>Puedes optar por no ser rastreado por Google Analytics utilizando el <a href="https://tools.google.com/dlpage/gaoptout?hl=es" target="_blank">complemento de inhabilitación para navegadores de Google Analytics</a>.</p>
            <h2>Contacto</h2>
            <p>Si tienes alguna pregunta sobre nuestra política de privacidad, puedes contactarnos en jonaypelluz&#64;gmail&#46;com.</p>`,
        cookiesContent: `<p>Este sitio web utiliza cookies para mejorar la experiencia de navegación y analizar el tráfico. A continuación, detallamos qué son las cookies y cómo las utilizamos en jueletrado.com.</p>
            <h2>¿Qué son las cookies?</h2>
            <p>Las cookies son pequeños archivos de texto que los sitios web colocan en tu dispositivo para almacenar información sobre tus preferencias y visitas anteriores. En nuestro caso, utilizamos cookies para entender cómo los usuarios interactúan con el sitio web a través de Google Analytics.</p>    
            <h2>Tipos de Cookies que utilizamos</h2>
            <ul>
            <li><strong>Cookies de análisis:</strong> Utilizadas por Google Analytics para recopilar información sobre el comportamiento de los usuarios en nuestro sitio web. Estos datos son anónimos y nos permiten mejorar los contenidos.</li>
            </ul>  
            <h2>¿Cómo desactivar las cookies?</h2>
            <p>Puedes desactivar las cookies ajustando la configuración de tu navegador, o utilizar el complemento de inhabilitación para navegadores de Google Analytics que puedes descargar desde <a href="https://tools.google.com/dlpage/gaoptout?hl=es" target="_blank">aquí</a>.</p>
            <h2>Contacto</h2>
            <p>Si tienes alguna duda sobre el uso de cookies en nuestro sitio web, puedes contactarnos en jonaypelluz&#64;gmail&#46;com.</p>`,
    },
    en: {
        privacyContent: `<p>At jueletrado.com, we value your privacy. Although we do not directly collect personal data, this website uses Google Analytics to improve the user experience by analyzing traffic and browsing behavior in an anonymous manner.</p>
            <h2>Data Collected</h2>
            <p>Through Google Analytics, the following anonymous data is collected:</p>
            <ul>
            <li>IP address (anonymized)</li>
            <li>Approximate geographical location</li>
            <li>Duration of the visit</li>
            <li>Pages visited</li>
            <li>Device and browser type</li>
            </ul>

            <h2>Use of Information</h2>
            <p>This data is used solely to analyze user behavior on our website and to improve the content and services offered. We do not sell, share, or use this data to personally identify users.</p>

            <h2>Use of Cookies</h2>
            <p>Google Analytics uses cookies to track users' interactions with the website. Cookies are small files stored on your device that help analyze website usage.</p>

            <h2>Third-Party Services</h2>
            <p>We use Google Analytics, a web analysis service provided by Google Inc. Google uses the data collected to track and examine the use of this website, prepare reports, and share them with other Google services.</p>

            <h2>Your Rights</h2>
            <p>You can opt-out of being tracked by Google Analytics by using the <a href="https://tools.google.com/dlpage/gaoptout?hl=en" target="_blank">Google Analytics Opt-out Browser Add-on</a>.</p>

            <h2>Contact</h2>
            <p>If you have any questions about our privacy policy, you can contact us at jonaypelluz&#64;gmail&#46;com.</p>`,
        cookiesContent: `<p>This website uses cookies to enhance the browsing experience and analyze traffic. Below, we explain what cookies are and how we use them on jueletrado.com.</p>
            <h2>What are cookies?</h2>
            <p>Cookies are small text files that websites place on your device to store information about your preferences and previous visits. In our case, we use cookies to understand how users interact with the website through Google Analytics.</p>
            <h2>Types of cookies we use</h2>
            <ul>
            <li><strong>Analytics cookies:</strong> Used by Google Analytics to collect information about user behavior on our website. This data is anonymous and helps us improve the content.</li>
            </ul>
            <h2>How to disable cookies</h2>
            <p>You can disable cookies by adjusting your browser settings, or use the Google Analytics opt-out browser add-on, which you can download <a href="https://tools.google.com/dlpage/gaoptout?hl=en" target="_blank">here</a>.</p>
            <h2>Contact</h2>
            <p>If you have any questions about the use of cookies on our website, you can contact us at jonaypelluz&#64;gmail&#46;com.</p>
            `,
    },
};

export { LegalContent };
